import { useAuthContext } from 'contexts';
import {
  GetTicketsForTicketsListPageQuery,
  ListMeetingServiceRequestsSortByInput,
  useGetMeetingServiceRequestsCountForTicketsListTablePaginationQuery,
  useGetTicketsForTicketsListPageQuery,
} from 'generated';
import {
  createContext,
  useContext,
  FC,
  ReactNode,
  useMemo,
  SetStateAction,
  Dispatch,
  useState,
  JSXElementConstructor,
  ReactElement,
} from 'react';
import { useTicketsListLocationContext } from './LocationContext';
import { useTenantLocalStorage } from 'hooks/useTenantLocalStorage';
import { message } from '@robinpowered/ui-kit';
import { NoticeType } from 'antd/es/message/interface';

type RefetchFilters = {
  skip?: number | undefined;
  after?: string | undefined;
};
type TicketsListPageValue = {
  data: GetTicketsForTicketsListPageQuery | undefined;
  refetchTickets: (filters?: RefetchFilters) => void;
  loading: boolean;
  meetingServiceRequestsCount: number;
  refetchMeetingServiceRequestsCount: () => void;
  sortByForListServiceRequestsQuery:
    | ListMeetingServiceRequestsSortByInput
    | null
    | undefined;
  setSortByForListServiceRequestsQuery: Dispatch<
    SetStateAction<ListMeetingServiceRequestsSortByInput | null | undefined>
  >;
  setSelectedServiceId: Dispatch<SetStateAction<string | undefined>>;
  selectedServiceId: string | undefined;
  toastContextHolder: ReactElement<
    unknown,
    string | JSXElementConstructor<unknown>
  >;
  toastMessage: (type: NoticeType, message: string) => void;
};

const TicketsListPageContext = createContext<TicketsListPageValue>({
  data: undefined,
  refetchTickets: () => null,
  loading: true,
  meetingServiceRequestsCount: 0,
  refetchMeetingServiceRequestsCount: () => null,
  setSortByForListServiceRequestsQuery: () => null,
  sortByForListServiceRequestsQuery: null,
  setSelectedServiceId: () => null,
  selectedServiceId: undefined,
  toastContextHolder: <></>,
  toastMessage: () => null,
});

type Props = {
  children: ReactNode;
};

export const TICKETS_PER_TABLE_PAGE = 15;

export const TicketsListPageContextProvider: FC<Props> = ({ children }) => {
  const { loading: loadingAuth } = useAuthContext();
  // The selected service for opening the 'service details sidebar'
  const [selectedServiceId, setSelectedServiceId] = useState<
    string | undefined
  >();
  // Kind of hacky, but handles the loading state not properly updating when refetching
  // only use it in this file
  const [isRefetching, setIsRefetching] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();
  const toastMessage = (type: NoticeType, message: string) => {
    messageApi.open({
      type,
      content: message,
    });
  };

  const {
    selectedLocations,
    locationsUserCanManage,
    loading: loadingLocations,
  } = useTicketsListLocationContext();

  const [
    sortByForListServiceRequestsQuery,
    setSortByForListServiceRequestsQuery,
  ] = useTenantLocalStorage<ListMeetingServiceRequestsSortByInput | null>(
    'list-meeting-service-requests-table-sort',
    null
  );

  const { data, loading, refetch } = useGetTicketsForTicketsListPageQuery({
    variables: {
      input: {
        first: TICKETS_PER_TABLE_PAGE,
        sortBy: sortByForListServiceRequestsQuery || undefined,
        skip: undefined,
        after: undefined,
      },
    },
    skip: loadingAuth,
  });

  const availableInAnyBuildingIdsOrTheirDescendants = useMemo(() => {
    return selectedLocations.length
      ? selectedLocations.filter((location) => !!location)
      : locationsUserCanManage.map((location) => {
          return location?.id || '';
        });
  }, [selectedLocations, locationsUserCanManage]);

  const {
    data: dataForCountServiceRequests,
    refetch: refetchMeetingServiceRequestsCount,
  } = useGetMeetingServiceRequestsCountForTicketsListTablePaginationQuery({
    variables: {
      input: {
        filters: {
          availableInAnyBuildingIdsOrTheirDescendants,
        },
      },
    },
    skip:
      loadingLocations ||
      availableInAnyBuildingIdsOrTheirDescendants.length < 1,
  });

  return (
    <TicketsListPageContext.Provider
      value={{
        meetingServiceRequestsCount:
          dataForCountServiceRequests?.countMeetingServiceRequests.count || 0,
        refetchMeetingServiceRequestsCount: () => {
          // TODO once filters are ready, make this have access to them
          refetchMeetingServiceRequestsCount();
        },
        data,
        loading: loading || isRefetching,
        setSortByForListServiceRequestsQuery,
        sortByForListServiceRequestsQuery,
        setSelectedServiceId,
        selectedServiceId,
        refetchTickets: (filters?: RefetchFilters) => {
          setIsRefetching(true);

          // TODO once filters are ready, make this have access to them
          refetch({
            input: {
              first: TICKETS_PER_TABLE_PAGE,
              skip: filters?.skip,
              after: filters?.after,
            },
          }).finally(() => {
            setIsRefetching(false);
          });
        },
        toastContextHolder: contextHolder,
        toastMessage,
      }}
    >
      {children}
    </TicketsListPageContext.Provider>
  );
};

export const useTicketsListPageContext = (): TicketsListPageValue => {
  return useContext(TicketsListPageContext);
};
