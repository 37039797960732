import styled from '@emotion/styled';
import { ServiceDetailCard } from 'components/common/ServiceDetailCard/ServiceDetailCard';
import { AnsweredQuestions } from 'components/common/AnsweredQuestions/AnsweredQuestions';
import { useServiceDetailsSidebarContext } from 'components/ServiceDetailsSidebar/contexts/ServiceDetailsSidebarContext';
import moment from 'moment';
import { useMemo } from 'react';

export const Body = () => {
  const { serviceDetails, loading, locationData } =
    useServiceDetailsSidebarContext();

  const locationName = useMemo(() => {
    return [
      locationData?.getLevelById?.name,
      locationData?.getLocationById?.name,
    ]
      .filter((x) => x)
      .join(', ');
  }, [locationData]);

  return (
    <Wrapper>
      <ServiceDetailCard
        serviceId={serviceDetails?.id}
        status={serviceDetails?.meetingServiceRequestStatus}
        eventTitle={serviceDetails?.regardsEventAtSpace.event?.title}
        categoryName={serviceDetails?.meetingService.category.name}
        serviceName={serviceDetails?.meetingService.name}
        requestedBy={serviceDetails?.requester}
        dueDate={moment(serviceDetails?.regardsEventAtSpace.eventStart).format(
          'ddd, MMM DD, h:mm a'
        )}
        spaceName={serviceDetails?.regardsEventAtSpace.space?.name}
        locationName={locationName}
        numOfAttendees={
          serviceDetails?.regardsEventAtSpace.event?.invitees.length
        }
        serviceKey={serviceDetails?.key}
        assignees={serviceDetails?.assignees}
        loading={loading}
      />
      <AnsweredQuestions completedForm={serviceDetails?.completedForm} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  padding: 1rem;
  flex: 1;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
`;
