import { Select, Tag } from '@robinpowered/ui-kit';
import {
  MeetingServiceRequestStatus,
  UpdatableMeetingServiceRequestStatus,
  useGetMeetingServiceRequestByIdUpdatedStatusLazyQuery,
  useUpdateMeetingServiceRequestStatusMutation,
} from 'generated';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SpinnerLoader } from '@robinpowered/design-system';
import { Sentry } from 'lib/sentry';
import { useTicketsListPageContext } from 'pages/TicketsListPage/contexts/TicketsListPageContext';

const isUpdateAbleStatus = (
  status: MeetingServiceRequestStatus
): status is UpdatableMeetingServiceRequestStatus => {
  return Object.values(UpdatableMeetingServiceRequestStatus).includes(
    status as UpdatableMeetingServiceRequestStatus
  );
};

type Props = {
  status: MeetingServiceRequestStatus;
  meetingServiceRequestId: string;
};

export const ServiceRequestStatus = ({
  status,
  meetingServiceRequestId,
}: Props) => {
  const { t } = useTranslation('TicketsListPage');
  const { toastMessage } = useTicketsListPageContext();

  const getTagColor = (status: MeetingServiceRequestStatus) => {
    switch (status) {
      case MeetingServiceRequestStatus.Blocked:
      case MeetingServiceRequestStatus.NeedsApproval:
        return 'warning';
      case MeetingServiceRequestStatus.InProgress:
        return 'processing';
      case MeetingServiceRequestStatus.Done:
        return 'success';
      case MeetingServiceRequestStatus.Cancelled:
      case MeetingServiceRequestStatus.Rejected:
        return 'error';
      default:
        return 'default';
    }
  };

  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [updateStatus] = useUpdateMeetingServiceRequestStatusMutation();

  const [getMeetingServiceRequestById] =
    useGetMeetingServiceRequestByIdUpdatedStatusLazyQuery();

  const [currentStatus, setCurrentStatus] = useState(status);

  useEffect(() => {
    setCurrentStatus(status);
  }, [status]);

  const handleUpdateMeetingStatus = useCallback(
    (newStatus: UpdatableMeetingServiceRequestStatus) => {
      const variables = {
        input: {
          meetingServiceRequestId: meetingServiceRequestId,
          updatableMeetingServiceRequestStatus: newStatus,
        },
      };
      setUpdatingStatus(true);
      updateStatus({
        variables,
      })
        .then((res) => {
          if (
            res.data?.updateMeetingServiceRequestStatus?.__typename ===
            'UpdateMeetingServiceRequestStatusSuccessResponse'
          ) {
            setCurrentStatus(
              res.data.updateMeetingServiceRequestStatus
                .meetingServiceRequestStatus
            );
            setUpdatingStatus(false);
            getMeetingServiceRequestById({
              variables: {
                meetingServiceRequestId: meetingServiceRequestId,
              },
              fetchPolicy: 'network-only',
            });
          } else if (
            res.data?.updateMeetingServiceRequestStatus?.__typename ===
            'UpdateMeetingServiceRequestStatusErrorResponse'
          ) {
            const errorReason = t(
              `tickets.error.${res.data.updateMeetingServiceRequestStatus.reason}`
            );
            toastMessage('error', errorReason);
            Sentry.captureMessage(errorReason);
            setUpdatingStatus(false);
          }
        })
        .catch((err) => {
          toastMessage('error', t('tickets.error.default'));
          Sentry.captureException(err);
        })
        .finally(() => {
          setUpdatingStatus(false);
        });
    },
    [
      meetingServiceRequestId,
      t,
      toastMessage,
      updateStatus,
      getMeetingServiceRequestById,
    ]
  );

  return (
    <div>
      {isUpdateAbleStatus(status) ? (
        <Select
          variant="borderless"
          style={{ width: '150px' }}
          showSearch={false}
          value={currentStatus}
          onChange={(status) => {
            handleUpdateMeetingStatus(
              status as UpdatableMeetingServiceRequestStatus
            );
          }}
          disabled={updatingStatus}
          labelRender={(value) => {
            return (
              <Tag
                color={getTagColor(
                  value.value as UpdatableMeetingServiceRequestStatus
                )}
                style={{ marginInlineEnd: 4 }}
              >
                {updatingStatus ? <SpinnerLoader /> : value.label}
              </Tag>
            );
          }}
          options={[
            {
              label: t('tickets.status.todo'),
              value: UpdatableMeetingServiceRequestStatus.Todo,
            },
            {
              label: t('tickets.status.in_progress'),
              value: UpdatableMeetingServiceRequestStatus.InProgress,
            },
            {
              label: t('tickets.status.blocked'),
              value: UpdatableMeetingServiceRequestStatus.Blocked,
            },
            {
              label: t('tickets.status.done'),
              value: UpdatableMeetingServiceRequestStatus.Done,
            },
            {
              label: t('tickets.status.cancelled'),
              value: UpdatableMeetingServiceRequestStatus.Cancelled,
            },
          ]}
          optionRender={(option) => (
            <Tag
              color={getTagColor(
                option.value as UpdatableMeetingServiceRequestStatus
              )}
              style={{ marginInlineEnd: 4 }}
            >
              {option.label}
            </Tag>
          )}
        />
      ) : (
        <Tag color={getTagColor(status)} style={{ marginInlineEnd: 4 }}>
          {t(`tickets.status.${status.toLowerCase()}`)}
        </Tag>
      )}
    </div>
  );
};
