import { Body } from '@robinpowered/design-system';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import moment from 'moment';
import { Button, TableColumnsType } from '@robinpowered/ui-kit';
import { useTicketsListPageContext } from 'pages/TicketsListPage/contexts/TicketsListPageContext';
import { UsersCell } from '../../common/TableCells/Users';
import { ListMeetingServiceRequestsSortByCriteria, SortOrder } from 'generated';
import { Assignee } from 'components/common/TableCells/Assignee';
import { useAuthContext } from 'contexts';
import { TicketsRoutes } from 'App';
import { useNavigate } from 'react-router-dom';
import { ServiceRequestStatus } from 'components/common/TableCells/ServiceStatus';

export type TicketsListTableDataType = {
  status: JSX.Element;
  ticketName: JSX.Element;
  startAt: string;
  serviceName: JSX.Element;
  space: string;
  key: string;
  requester: JSX.Element;
  category: string;
  createdAt: string;
  updatedAt: string;
};

export const useManageTicketsListTable = () => {
  const { t } = useTranslation('TicketsListPage');
  const { currentOrg } = useAuthContext();
  const { data, sortByForListServiceRequestsQuery, setSelectedServiceId } =
    useTicketsListPageContext();
  const navigate = useNavigate();

  const ticketsList = data?.listMeetingServiceRequests.meetingServiceRequests;

  // From AntDesign table docs: please try to leave one column at least without width to fit fluid layout
  const columns: TableColumnsType = [
    {
      title: t('table.columns.status'),
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: t('table.columns.ticket_name'),
      dataIndex: 'ticketName',
      key: 'ticketName',
    },
    {
      title: t('table.columns.start_at'),
      dataIndex: 'startAt',
      key: 'startAt',
      width: 175,
    },
    {
      title: t('table.columns.service_name'),
      dataIndex: 'serviceName',
      key: 'serviceName',
    },
    {
      title: t('table.columns.space'),
      dataIndex: 'space',
      key: 'space',
    },
    {
      title: t('table.columns.event_title'),
      dataIndex: 'eventTitle',
      key: 'eventTitle',
    },
    {
      title: t('table.columns.service_key'),
      dataIndex: 'serviceKey',
      key: 'serviceKey',
    },
    {
      title: t('table.columns.requester'),
      dataIndex: 'requester',
      key: 'requester',
    },
    {
      title: t('table.columns.assignee'),
      dataIndex: 'assignee',
      key: 'assignee',
    },
    {
      title: t('table.columns.category'),
      dataIndex: 'category',
      key: 'category',
      width: 175,
    },
    {
      title: t('table.columns.created_at'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      sortOrder:
        sortByForListServiceRequestsQuery?.criteria ===
        ListMeetingServiceRequestsSortByCriteria.CreatedAt
          ? sortByForListServiceRequestsQuery?.order === SortOrder.Ascending
            ? 'ascend'
            : 'descend'
          : undefined,
    },
    {
      title: t('table.columns.updated_at'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: true,
      sortOrder:
        sortByForListServiceRequestsQuery?.criteria ===
        ListMeetingServiceRequestsSortByCriteria.UpdatedAt
          ? sortByForListServiceRequestsQuery?.order === SortOrder.Ascending
            ? 'ascend'
            : 'descend'
          : undefined,
      width: 175,
    },
  ];

  const tableData = useMemo(() => {
    return (
      ticketsList?.map((service) => {
        return {
          key: service.id,
          status: (
            <ServiceRequestStatus
              status={service.meetingServiceRequestStatus}
              meetingServiceRequestId={service.id}
            />
          ),
          ticketName: (
            <Button
              type="link"
              aria-label="Open the ticket details in sidebar"
              onClick={() => {
                setSelectedServiceId(service.id);
                navigate(
                  `/${currentOrg?.slug}/tickets/${TicketsRoutes.TICKETS_LIST}/${service.key}`
                );
              }}
            >
              <TruncatedName title={service.title}>
                {service.title}
              </TruncatedName>
            </Button>
          ),
          startAt: moment(service.regardsEventAtSpace.eventStart).format(
            'MMM D h:mm a'
          ),
          serviceName: (
            <TruncatedName title={service.meetingService.name}>
              {service.meetingService.name}
            </TruncatedName>
          ),
          space: service.regardsEventAtSpace.space?.name || '',
          eventTitle:
            service.regardsEventAtSpace.event?.title ||
            t('table.rows.event.no_title'),
          serviceKey: service.key,
          requester: <UsersCell users={[service.requester]} />,
          category: service.meetingService.category.name,
          assignee: <Assignee assignees={service.assignees} />,
          createdAt: moment(service.createdAt).format('MMM D h:mm a'),
          updatedAt: moment(service.updatedAt).format('MMM D h:mm a'),
        };
      }) || []
    );
  }, [ticketsList, t, setSelectedServiceId, navigate, currentOrg?.slug]);

  return {
    tableData,
    columns,
  };
};

const TruncatedName = styled(Body.Small)`
  max-width: 350px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`;
