import { Drawer } from '@robinpowered/ui-kit';
import styled from '@emotion/styled';
import { Body } from './Body';
import { Footer } from './Footer';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useServiceDetailsSidebarContext } from '../contexts/ServiceDetailsSidebarContext';

// For rendering in mobile views where the sider takes too much space on screen
export const MobileDrawer = () => {
  const { t } = useTranslation('ServiceDetailsSidebar');
  const { serviceId, setSelectedServiceId, serviceDetails } =
    useServiceDetailsSidebarContext();
  // Whether or not the sidebar is open/closed
  const collapsed = useMemo(() => !serviceId, [serviceId]);

  const title = useMemo(() => {
    if (!serviceDetails) return '';

    return serviceDetails?.regardsEventAtSpace.event?.title
      ? t(`title`, {
          serviceName: serviceDetails?.meetingService.name,
          eventTitle: serviceDetails?.regardsEventAtSpace.event?.title,
          key: serviceDetails?.key,
        })
      : t(`title_no_event`, {
          serviceName: serviceDetails?.meetingService.name,
          key: serviceDetails?.key,
        });
  }, [serviceDetails, t]);

  return (
    <Drawer
      width={520}
      open={!collapsed}
      title={title}
      maskClosable={true}
      onClose={() => setSelectedServiceId(undefined)}
    >
      <Wrapper>
        <Body />
        <Footer />
      </Wrapper>
    </Drawer>
  );
};

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
