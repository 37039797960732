import { createContext, useContext, FC, ReactNode } from 'react';
import {
  GetLocationNameDetailsForServiceRequestDetailsSidebarQuery,
  GetMeetingServicesForAdminTableListQuery,
  GetServiceRequestByIdForDetailSidebarQuery,
  useGetLocationNameDetailsForServiceRequestDetailsSidebarQuery,
  useGetServiceRequestByIdForDetailSidebarQuery,
} from 'generated';

export type MeetingServiceType =
  GetMeetingServicesForAdminTableListQuery['listMeetingServices']['meetingServices'][0];

type ServiceDetailsSidebarValue = {
  serviceId: string | undefined;
  setSelectedServiceId: (id?: string) => void;
  serviceDetails:
    | GetServiceRequestByIdForDetailSidebarQuery['getMeetingServiceRequestById']['meetingServiceRequest']
    | undefined;
  loading: boolean;
  locationData:
    | GetLocationNameDetailsForServiceRequestDetailsSidebarQuery
    | undefined;
};

const ServiceDetailsSidebarContext = createContext<ServiceDetailsSidebarValue>({
  serviceId: undefined,
  setSelectedServiceId: () => null,
  serviceDetails: undefined,
  loading: false,
  locationData: undefined,
});

export const ServiceDetailsSidebarContextProvider: FC<{
  serviceId: string;
  setSelectedServiceId: (id: string | undefined) => void;
  children: ReactNode;
}> = ({ serviceId, setSelectedServiceId, children }) => {
  const { data, loading } = useGetServiceRequestByIdForDetailSidebarQuery({
    variables: {
      id: serviceId,
    },
    skip: !serviceId,
  });

  const serviceDetails =
    data?.getMeetingServiceRequestById.meetingServiceRequest;

  const { data: locationData, loading: locationLoading } =
    useGetLocationNameDetailsForServiceRequestDetailsSidebarQuery({
      variables: {
        locationId:
          serviceDetails?.regardsEventAtSpace.space?.locationId.toString() ||
          '',
        levelId: serviceDetails?.regardsEventAtSpace.space?.levelId || '',
        hasLevelId: !!serviceDetails?.regardsEventAtSpace.space?.levelId,
      },
      skip: !serviceDetails?.regardsEventAtSpace.space?.locationId,
    });

  return (
    <ServiceDetailsSidebarContext.Provider
      value={{
        serviceId,
        setSelectedServiceId,
        serviceDetails,
        locationData,
        loading: loading || locationLoading,
      }}
    >
      {children}
    </ServiceDetailsSidebarContext.Provider>
  );
};

export const useServiceDetailsSidebarContext =
  (): ServiceDetailsSidebarValue => {
    return useContext(ServiceDetailsSidebarContext);
  };
